<template>
  <div class="fire">
    <Header />
    <div class="content">
      <div class="fire-title">智慧消防解决方案</div>
      <p class="title-content">
        近年来，信息技术飞速发展，现代城市的信息化水平有了很大提高，逐步形成了“智慧城市”的建设理念。消防安全作为城市建设的重要一环，逐步形成“智慧城市”相匹配的“智慧消防”。
      </p>
      <p class="title-content">
        智慧消防解决方案通过对消防隐患的全态监控，结合应急联动保障，实现火灾隐患早发现，早消除。
      </p>
      <div style="text-align: center">
        <img
          class="content_img"
          src="https://aierbanjia-official-web.oss-cn-chengdu.aliyuncs.com/web/detail_xf.png"
          alt="智慧园区解决方案"
        />
      </div>
      <div class="fire-content">
        <h4 class="h4">智慧消防技术架构</h4>
        <div class="basicRules">基本原则</div>
        <div class="fire-box">
          <div class="fire-item-top">
            <div class="item" v-for="item in basicList" :key="item.name">
              <img style="width: 0.3rem;height:0.3rem;object-fit:cover;margin-right:0.1rem;vertical-align: middle;" :src="item.url" alt="">
              <span style="font-size: 0.32rem;font-weight: 400;color: #27201B;vertical-align: middle;">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="basicRules1">建设重点项目</div>
        <div class="fire-box">
          <div class="fire-item-top">
            <div class="item" v-for="item in reportList" :key="item.name">
              <img style="width: 0.3rem;height:0.3rem;object-fit:cover;margin-right:0.1rem;vertical-align: middle;" :src="item.url" alt="">
              <span style="font-size: 0.32rem;font-weight: 400;color: #27201B;vertical-align: middle;">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="system">五大应用系统</div>
        <div class="system-box">
          <div class="left">
            <div class="left-top">城市远程监控系统</div>
            <div class="left-bottom">
              <div>
                <p>报警联动</p>
                <p>设施巡检</p>
                <p>单位管理</p>
                <p>消防监管</p>
                <p style="text-align:center;">......</p>
              </div>
            </div>
          </div>
          <div class="left">
            <div class="left-top">实战指挥平台</div>
            <div class="left-bottom">
              <div>
                <p>灾情信息实时化</p>
                <p>作战对象精准化</p>
                <p>力量信息精确化</p>
                <p>作战指挥可视化</p>
                <p style="text-align:center;">......</p>
              </div>
            </div>
          </div>
          <div class="left">
            <div class="left-top">住宅消防预警系统</div>
            <div class="left-bottom">
              <div>
                <p style="margin-bottom:0.3rem;">智慧用电</p>
                <p style="margin-bottom:0.3rem;">智慧用气</p>
                <p style="margin-bottom:0.3rem;">智慧用水</p>
                <p style="text-align:center;">......</p>
              </div>
            </div>
          </div>
          <div class="left">
            <div class="left-top">编制和管理应用平台</div>
            <div class="left-bottom">
              <div>
                <p style="margin-bottom:0.3rem;">要素展示 动态演变</p>
                <p style="margin-bottom:0.3rem;">数据交换 辅助指挥</p>
                <p style="margin-bottom:0.3rem;">熟悉演练 教学推演</p>
                <p style="text-align:center;">......</p>
              </div>
            </div>
          </div>
          <div class="left">
            <div class="left-top">消防安全管理系统</div>
            <div class="left-bottom">
              <div>
                <p style="margin-bottom:0.3rem;">智慧医疗</p>
                <p style="margin-bottom:0.3rem;">智慧消防</p>
                <p style="margin-bottom:0.3rem;">智慧交通</p>
                <p style="text-align:center;">......</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer" @click="jumpTo">下一篇：智慧城管解决方案 >></div>
    </div>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  export default {
    name: 'Fire',
    components: { Header },
    data() {
      return {
        basicList: [
          { name: '突出精准防控', url: require('../../static/images/fire-fk.png') },
          { name: '突出协同共治', url: require('../../static/images/fire-gz.png') },
          { name: '突出服务实战', url: require('../../static/images/fire-sz.png') },
          { name: '突出服务民生', url: require('../../static/images/fire-ms.png') },
          { name: '突出警地融合', url: require('../../static/images/fire-rh.png') }
        ],
        reportList: [
          { name: '标准规范体系', url: require('../../static/images/fire-bztx.png') },
          { name: '安全保障体系', url: require('../../static/images/fire-aqbz.png') },
          { name: '运行维护体系', url: require('../../static/images/fire-yxwh.png') }
        ]
      }
    },
    methods: {
      jumpTo() {
        this.$router.push('/details/city')
      }
    }
  }
</script>

<style lang="scss" scoped>
.fire-title{
  font-size: 0.37rem;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-bottom: 0.7rem;
}
.title-content {
  font-weight: 400;
  color: #666666;
  line-height: 0.64rem;
  font-size: 0.3rem;
  margin-bottom: 0.6rem;
  text-indent: 2em;
}
.content_img {
  width: 9.15rem;
  height: 4.88rem;
}
h4{
  margin-top: 1rem;
  margin-bottom: 0.76rem;
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #27201B;
  height: 0.37rem;
}
.basicRules{
  margin-bottom: 0.68rem;
  width: 1.77rem;
  height: 0.56rem;
  background: #F79400;
  text-align: center;
  line-height: 0.56rem;
  color: #fff;
  font-size: 0.35rem;
  margin-bottom: 0.68rem;
}
.basicRules1{
  margin-bottom: 0.68rem;
  width: 2.48rem;
  height: 0.56rem;
  background: #F79400;
  text-align: center;
  line-height: 0.56rem;
  color: #fff;
  font-size: 0.35rem;
  margin-bottom: 0.68rem;
  margin-top:0.68rem;
}
.h4{
  font-size: 0.4rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #27201B;
}
.item{
  width: 2.8rem;
  height: 0.64rem;
  text-align: center;
  line-height: 0.5rem;
  background: rgba(247, 148, 0, 0.06);
  border: 1px solid #F79400;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
}
.fire-item-top{
  display: flex;
  flex-wrap: wrap;
}
.system{
  margin-top: 0.56rem;
  width: 2.56rem;
  height: 0.56rem;
  text-align: center;
  line-height: 0.56rem;
  background: #F89E19;
  font-size: 0.35rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 0.2rem;
}
.system-box{
  display: flex;
  flex-wrap: wrap;
  .left{
    margin-bottom: 0.15rem;
    .left-top{
      width: 2.86rem;
      height: 0.56rem;
      text-align: center;
      line-height: 0.56rem;
      background: #F79400;
      font-size: 0.32rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 0.16rem;
      margin-right: 0.2rem;
    }
    .left-bottom{
      width: 2.88rem;
      height: 3.48rem;
      background: rgba(247, 148, 0, 0.06);
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        font-size: 0.29rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27201B;
        margin-bottom: 0.16rem;
      }
    }
  }
}
.footer {
  cursor: pointer;
  margin-right: 0.2rem;
  text-align: right;
  width: 100%;
  height: 0.31rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-top: 0.4rem;
  margin-bottom: 0.6rem;
}
</style>